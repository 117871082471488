<template>
  <div class="widthcenter">
    <!-- 头部 -->
    <div class="header">
      <div class="fex">
        <img src="@/assets/2.png" alt class="imgicon" />
        <div v-for="(item,indx) in list" :key="indx" @click="changetabes(item.id)">
          <div class="mr" :class="active==indx?'tabliatactive':'tabliat'">{{ item.title }}</div>
        </div>
      </div>
      <div class="fex">
        <el-input
          @blur="changeblur"
          @keyup.enter.native="changeblur"
          placeholder="请输入内容"
          v-model="keywords"
          clearable
          size="small"
          suffix-icon="el-icon-search"
        ></el-input>
        <div class="fex">
          <div class="fex" style="margin-left: 15px;" v-if=" userinfo.name">
            <img src="@/assets/32.png" alt class="headPortrait" />
            <span style="color: rgba(56, 56, 56, 1);  cursor: pointer;">{{userinfo.name}}</span>
            <!-- <el-dropdown>
              <span style="color: rgba(56, 56, 56, 1);  cursor: pointer;">{{userinfo.name}}</span>
              <el-dropdown-menu slot="dropdown"  >
                <el-dropdown-item >退出登陆</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>-->
          </div>
          <div v-else @click="dialogFormVisible=true" class="login">登录</div>
        </div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div>
      <!-- 轮播图 -->
      <div class="jyb-focus">
        <el-carousel :interval="4000" type="card" height="435px">
          <el-carousel-item
            v-for="(item,index) in bannerLiat"
            :key="index"
            class="img_carou"
            :style="getstyle(item.image)"
            @click.native="linkTo(item)"
          ></el-carousel-item>
        </el-carousel>
      </div>
      <div class="block">
        <div class="centersss">
          <div class="left">
            <div class="curriculum" v-if="listData.length">
              <div v-for="(item,index) in listData" :key="index">
                <div class="titlesize">{{item.ex_title}}</div>
                <div class="curriculum_item" @click="ViewDetails(item)">
                  <img :src="item.image" alt class="curriculum_item_img" v-if="item.image" />
                  <img src="@/assets/2.png" alt v-else class="curriculum_item_img" />
                  <div class="curriculum_item_lin">
                    <div class="mt">{{ item.title }}</div>
                    <div class="fex mt">
                      <img src="@/assets/32.png" alt class="img" />
                      <span style="margin-left: 5px;" class="size">{{userinfo.name}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-empty description="暂无" v-else class="elempty"></el-empty>
          </div>
          <div class="right" v-if="userinfo.name">
            <img :src="userinfo.image" alt class="imgicon" v-if="userinfo.image" />
            <div class="Info">
              <div class="lin-bottom" v-if="userinfo.name">姓名：{{ userinfo.name }}</div>
              <div class="lin-bottom" v-if="userinfo.department">部门：{{ userinfo.department }}</div>
              <div class="lin-bottom" v-if="userinfo.posi">岗位：{{ userinfo.posi }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <el-dialog title="欢迎登录" :visible.sync="dialogFormVisible" width="350px" center>
      <el-form :model="form">
        <el-form-item>
          <el-input placeholder="账号" prefix-icon="el-icon-user-solid" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="密码" prefix-icon="el-icon-s-goods" v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="Login">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { menuList, Login, bannerList, indexList } from "../api/home.js";
export default {
  data() {
    return {
      active: 0,
      userinfo: {}, //用户登录信息
      keywords: "",
      list: [],
      listData: [],
      bannerLiat: [],
      dialogFormVisible: false,
      form: {
        name: "",
        password: ""
      }
    };
  },
  created() {
    if (localStorage.getItem("Token")) {
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    }

    this.menuListList();
    this.banner();
    this.indexList();
  },
  methods: {
    getstyle(img) {
      return {
        background: "url(" + "https://clo.litaifs.com/" + img + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: " no-repeat"
      };
    },
    // 查询首页的数据
    indexList(keywords) {
      indexList({ keywords: keywords }).then(res => {
        if (res.code == 200) {
          this.listData = res.data;
        }
      });
    },
    // 查询分类列表
    menuListList() {
      menuList().then(res => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    //查询banner列表
    banner() {
      bannerList().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.bannerLiat = res.data;
        }
      });
    },
    changetabes(item) {
      this.$router.push("/home?id=" + item);
    },
    // 点击轮播查看详情
    linkTo(item) {
      let Token = localStorage.getItem("Token");
      if (!Token) {
        this.dialogFormVisible = true;
      } else {
        let routeData = this.$router.resolve({
          path: "/banner",
          query: { id: item.id }
        });
        window.open(routeData.href, "_blank");
        this.dialogFormVisible = false;
      }
    },
    // 点击查看详情
    ViewDetails(item) {
      let Token = localStorage.getItem("Token");
      if (!Token) {
        this.dialogFormVisible = true;
      } else {
        let routeData = this.$router.resolve({
          path: "/video/detail",
          query: { id: item.id }
        });
        window.open(routeData.href, "_blank");
        this.dialogFormVisible = false;
      }
    },
    // 登录
    Login() {
      let body = {
        account: this.form.name,
        password: this.form.password
      };
      if (this.form.name || this.form.password) {
        Login(body).then(res => {
          if (res.code === 200) {
            localStorage.setItem("Token", res.data.id);
            this.userinfo = res.data;
            localStorage.setItem("userinfo", JSON.stringify(res.data));
            this.$message.success("登录成功");
            location.reload();
            setInterval(() => {
              this.dialogFormVisible = false;
            }, 500);
          } else if (res.code == 201) {
            uni.removeStorageSync("token");
            uni.removeStorageSync("unifo");
            this.$message.error(res.msg);
            return;
          } else if (res.code == 401) {
            uni.removeStorageSync("token");
            uni.removeStorageSync("unifo");
            this.$message.error(res.msg);
            return;
          }
        });
      } else {
        this.$message({
          message: "请输入账号或密码",
          type: "warning"
        });
      }
    },
    // 搜索
    changeblur() {
      this.indexList(this.keywords);
    }
  }
};
</script>
<style scoped lang="scss">
.jyb-focus {
  clear: both;
  position: relative;
  margin: auto;
  width: 1250px;
  height: 435px;
}
::v-deep .el-carousel {
  .el-carousel__item--card.is-active {
    width: 1400px !important;
    left: -350px;
  }
}
.block {
  width: 100%;
  margin: auto;
}
.centersss {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  .left {
    flex: 1;
    padding-left: 5px;
  }
  .right {
    width: 280px;
    margin-left: 20px;
    .Signin {
      width: 100%;
      height: 32px;
      border-radius: 2px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      padding: 0px 8px;
      box-sizing: border-box;
    }
    .imgicon {
      width: 190px;
      height: 244px;
      opacity: 1;
      border-radius: 5px;
      margin-top: 10px;
      margin-left: 30px;
    }
    .Info {
      padding: 5px 12px;
      box-sizing: border-box;
      margin-top: 10px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      color: rgba(51, 51, 51, 1);

      .lin-bottom {
        line-height: 30px;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
      }
    }
  }
}
</style>