<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
<!-- -->
<style lang="scss">
body {
  margin: 0px;
  // background:rgba(245, 245, 245, 1);
}
.mr {
  margin-right: 15px;
}
.header {
  width: 100%;
  height: 95px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .imgicon {
    width: 176px;
    margin-left: -20px;
  }
  .headPortrait {
    background: #ced2d7;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.fex {
  display: flex;
  align-items: center;
}
.curriculum {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .curriculum_item {
    width: 300px;
    margin: 0px 15px 18px 0px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    font-size: 14px;
    .curriculum_item_img {
      width: 100%;
      height: 158px;
    }
    .typecenter {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      width: 62px;
      height: 21px;
      line-height: 21px;
      background: rgba(64, 64, 64, 0.6);
      border-radius: 4px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }

    .curriculum_item_lin {
      box-sizing: border-box;
      padding: 5px 15px;
      font-size: 14px;
    }
  }
}
.curriculum .curriculum_item:nth-of-type(3n+0) {
  margin: 0px 0px 18px 0px;
}
.img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ced2d7;
}
.mt {
  margin-top: 5px;
}
.titlesize{
  font-weight: 700px;
  font-size: 18px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 10px;
}
.mb10{
  margin-bottom: 10px;
}
.tabliat {
  color: rgba(51, 51, 51, 1);
  cursor: pointer;
}
.tabliatactive {
  color: rgba(212, 48, 48, 1);
}
.notitle{
  color: #808080;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}
::v-deep.el-input--small .el-input__inner {
  border-radius: 20px;
}
.widthcenter{
  width: 1250px;
  margin: auto;
}
.elempty {
  margin: auto;
}
.login {
  width: 50px;
  padding: 2px 10px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid #000000;
  font-size: 14px;
  margin-left: 15px;
  cursor: pointer;
}
</style>