<template>
  <div class="widthcenter">
    <!-- 电脑端 -->
    <div class="web">
      <!-- 头部 -->
      <div class="header">
        <div class="fex">
          <img src="@/assets/2.png" alt class="imgicon"  @click="changehome"/>
          <div v-for="(item,indx) in list" :key="indx">
            <div class="mr" :class="activetitxt==item.id?'tabliatactive':'tabliat'" @click="changetab(item)">{{ item.title }}</div>
          </div>
        </div>
        <div class="fex">
          <div class="fex">
            <div class="fex" style="margin-left: 15px;" v-if=" userinfo.name">
              <img src="@/assets/32.png" alt class="headPortrait" />
              <span style="color: rgba(56, 56, 56, 1);">{{userinfo.name}}</span>
            </div>
            <div v-else @click="dialogFormVisible=true" class="login">登录</div>
          </div>
        </div>
      </div>
      <!-- 主要的内容 -->
      <div class="center">
        <!-- 左边 -->
        <div class="center_left">
          <div class="center_left_title fex">
            <div class="line"></div>
            <div>课程分类</div>
          </div>
          <div v-if="companydata.length">
          <div v-for="(item,index) in companydata" :key="index">
            <div
              class="center_left_item"
              :class="lefttabs==index?'left_active':''"
              @click="classification(index,item)"
            >{{ item.title}}</div>
          </div>
        </div>
          <div class="notitle" v-else>暂无~</div>
        </div>
        <!-- 右边 -->
        <div class="center_right">
          <div class="inputwidth mb10">
            <el-input
              placeholder="请输入内容"
              clearable
              size="small"
              suffix-icon="el-icon-search"
              v-model="keyword"
              @keyup.enter.native="changekeyword"
            ></el-input>
          </div>
          <div class="fex mr mb10">
            <div v-for="(item,index) in tabList" :key="index">
              <div
                :class="tabs==index?'tab_item_active':'Notselected'"
                class="tab_item"
                @click="tabtitle(index,item)"
              >{{ item.label }}</div>
            </div>
          </div>
          <div>
            <div class="curriculum" v-if="companyListData.length">
              <div
                class="curriculum_item"
                v-for="(item,index) in companyListData"
                :key="index"
                @click="ViewDetails(item)"
              >
                <img :src="item.image" alt class="curriculum_item_img" v-if="item.image" />
                <img src="@/assets/2.png" alt v-else class="curriculum_item_img" />
                <div class="curriculum_item_lin">
                  <div class="mt">{{item.title}}</div>
                  <div class="fex mt" v-if="userinfo">
                    <img src="@/assets/32.png" alt class="img" />
                    <span style="margin-left: 5px;" class="size">{{ userinfo.name }}</span>
                  </div>
                </div>
                <div
                  class="typecenter"
                >{{item.status==1?'未学习':item.status==2?'学习中':item.status==3?'已学习':'未学习' }}</div>
              </div>
            </div>
            <el-empty description="暂无" v-else class="elempty"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="欢迎登录" :visible.sync="dialogFormVisible" width="350px" center>
      <el-form :model="form">
        <el-form-item>
          <el-input placeholder="账号" prefix-icon="el-icon-user-solid" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="密码" prefix-icon="el-icon-s-goods" v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="Login">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { companyList, companyIndex, menuList ,Login} from "../api/home.js";
export default {
  data() {
    return {
      activetitxt: 0, //选中的title
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png", //头像
      keyword: "", //关键字搜索
      tabList: [
        {
          label: "全部",
          status: null
        },
        {
          label: "未学习",
          status: 1
        },
        {
          label: "学习中",
          status: 2
        },
        {
          label: "已学习",
          status: 3
        }
      ],
      tabs: 0,
      list: [],
      lefttabs: 0,
      companydata: [], //分类列表数据
      companyListData: [], //文件列表数据
      Id: Number,
      token: "",
      status: "", //学习进度
      userinfo: {}, //用户登录信息
      dialogFormVisible: false,
      form: {
        name: "",
        password: ""
      }
    };
  },
  created() {
    this.menuListList()
    if (localStorage.getItem("Token")) {
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    }
    this.activetitxt = this.$route.query.id;
    this.companyIdex(this.activetitxt);
  },
  methods: {
    // 切换学习状态事件
    tabtitle(index, item) {
      this.tabs = index;
      this.status = item.status;
      this.companyList(this.Id, this.keyword, this.status);
    },
    // 左侧分类
    classification(index, item) {
      this.tabs = 0;
      this.status = null;
      this.lefttabs = index;
      this.Id = item.id;
      this.companyList(item.id, this.keyword, this.status);
    },
    // 点击查看详情
    ViewDetails(item) {
      let Token = localStorage.getItem("Token");
      if (!Token) {
        this.dialogFormVisible = true;
      } else {
        let routeData = this.$router.resolve({ path: '/video/detail', query: { id: item.id } })
         window.open(routeData.href, '_blank')
        this.dialogFormVisible = false;
      }
    },
    //  查询子分类列表
    companyIdex(menu_id) {
      companyIndex({menu_id:menu_id}).then(res => {
        if (res.code == 200) {
          this.companydata = res.data;
          if(this.companydata.length!==0){
            this.Id = res.data[0].id;
            this.companyList(res.data[0].id, this.keyword, this.status);
          }else{
            this.companyList()
          }
        }
      });
    },
    // 查询子分类文件列表
    companyList(id, keyword, status) {
      let promes = {
        id: id,
        keywords: keyword,
        status: status
      };
      companyList(promes).then(res => {
        if (res.code === 200) {
          this.companyListData = res.data;
        }
      });
    },
    // 关键字搜索
    changekeyword() {
      this.companyList(this.Id, this.keyword, this.status);
    },
    // // 查询分类列表
    menuListList() {
      menuList().then(res => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    // 切换目录
    changetab(item){
      this.activetitxt = item.id
      this.companyIdex(item.id)
    },
    // 返回首页
    changehome(){
       this.$router.push("/");
    },
        // 登录
        Login() {
      let body = {
        account: this.form.name,
        password: this.form.password
      };
      if (this.form.name || this.form.password) {
        Login(body).then(res => {
          if (res.code === 200) {
            localStorage.setItem("Token", res.data.id);
            this.userinfo = res.data;
            localStorage.setItem("userinfo", JSON.stringify(res.data));
            this.$message.success("登录成功");
            location.reload()
            setInterval(() => {
              this.dialogFormVisible = false;
            }, 500);
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      } else {
        this.$message({
          message: "请输入账号或密码",
          type: "warning"
        });
      }
    },

  }
};
</script>
<style scoped lang="scss">
.tab_item {
  font-size: 14px;
  padding: 7px 17px;
  cursor: pointer;
}
.Notselected {
  color: rgba(128, 128, 128, 1);
  border: 1px solid rgba(166, 166, 166, 1);
}
.tab_item_active {
  color: #ffffff;
  background: rgba(212, 48, 48, 1);
  border: 1px solid rgba(212, 48, 48, 1);
}
.left_active {
  background: rgba(255, 247, 247, 1);
}

.web {
  width: 100%;
  .center {
    padding-left: 3px;
    display: flex;
    margin: auto;
    .center_left {
      width: 205px;
      opacity: 1;
      min-height: 780px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      margin-right: 20px;
      .center_left_title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid rgba(227, 227, 227, 1);
        font-size: 16px;
        color: rgba(56, 56, 56, 1);
        .line {
          width: 3px;
          height: 15px;
          background: rgba(212, 48, 48, 1);
          margin: 0px 5px;
        }
      }
      .center_left_item {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: rgba(56, 56, 56, 1);
        height: 20px;
        padding: 5px 0px 5px 20px;
        margin-top: 8px;
      }
    }
    .inputwidth {
      width: 300px;
    }
    .center_right {
      flex: 1;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      min-height: 780px;
      padding: 17px 30px;
    }
  }
}

@media (max-width: 750px) {
  .web {
    width: 100%;
    .size {
      font-size: 14px;
    }
    .img {
      background: #ced2d7;
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
    .header {
      width: 100%;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px 0px 10px;
      box-sizing: border-box;
      .imgicon {
        width: 130px;
      }
      .headPortrait {
        background: #ced2d7;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    .center {
      display: flex;
      .center_left {
        width: 110px;
        opacity: 1;
        min-height: 90vh;
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        margin-right: 10px;
        .center_left_title {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid rgba(227, 227, 227, 1);
          font-size: 14px;
          color: rgba(56, 56, 56, 1);
          .line {
            width: 3px;
            height: 15px;
            background: rgba(212, 48, 48, 1);
            margin: 0px 5px;
          }
        }
        .center_left_item {
          cursor: pointer;
          font-size: 13px;
          font-weight: 400;
          color: rgba(56, 56, 56, 1);
          height: 20px;
          padding: 5px 0px 5px 10px;
          margin-top: 8px;
        }
      }
      .center_right {
        flex: 1;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        min-height: 780px;
        padding: 17px 10px;
        .inputwidth {
          width: 90%;
        }
        .tab_item {
          font-size: 12px;
          padding: 7px;
          cursor: pointer;
        }
        .curriculum {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .curriculum_item {
            width: 100%;
            margin: 0px 18px 18px 0px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            position: relative;
            .typecenter {
              position: absolute;
              top: 0;
              right: 0;
              width: 62px;
              height: 21px;
              line-height: 21px;
              font-size: 10px;
              background: rgba(64, 64, 64, 0.6);
              border-radius: 4px;
            }
            .curriculum_item_img {
              width: 100%;
              height: 120px;
            }
            .curriculum_item_lin {
              box-sizing: border-box;
              padding: 5px 15px;
            }
          }
        }
      }
    }
  }
}
</style>
