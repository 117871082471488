import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index from '../views/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    
  },
  {
    path: '/video/detail',
    name: 'video',
    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue'),    
    
  },
  {
    path: '/banner',
    name: 'banner',
    component: () => import(/* webpackChunkName: "about" */ '../views/bannerdetail.vue'),    
    
  },
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import(/* webpackChunkName: "about" */ '../views/iframe.vue'),    
    
  },
  // bannerdetail
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
